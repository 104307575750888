import { useCallback, useState } from "react";

function useHttp() {

    const [process, setProcess] = useState('waiting')

    const request = useCallback( async (url, method = 'GET', body = null, headers = {'Content-Type': 'aplication/json'}) => {
        setProcess('loading')
        try {
            const response = await fetch(url, {method, body, headers});

            if(!response.ok) {
                throw new Error(`Could not fetch ${url}, status: ${response.status}`)
            }
            const data = await response.json()
            return data
        } catch(e) {
            setProcess('error')
            throw e
        }
    }, [])


    return { request, process, setProcess };
}

export default useHttp;