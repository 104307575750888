import useHttp from '../hooks/http.hook'

function GrilMenuApi() {
    const { request, process, setProcess } = useHttp();

    // const _api = "http://localhost:8888/"
    // const _api = "https://api.capsqi.com/"
    const _api = "https://api.grill-zone-cafe.com/"
    const _media = "https://media.grill-zone-cafe.com/"

    const getAllCategories = async () => {
        const res = await request(`${_api}categories/all`);
        return res.map(_transformCategory)
    }

    const getSubcategoriesOfCategory = async (categoryId) => {
        const res = await request(`${_api}subcategories/category/${categoryId}`);
        return res.map(_transformSubcategory)
    }

    const getProductsOfSubcategory = async (subcategoryId) => {
        const res = await request(`${_api}products/subcategory/${subcategoryId}`);
        return res.map(_transformProduct)
    }

    const getProductsByName = async (name) => {
        const res = await request(`${_api}search/${name}`);
        return res.map(_transformProduct)
    }

    const getProductsById = async (ids) => {
        const idsSts = `${ids}`
        const res = await request(`${_api}products/ids/${idsSts}`);
        return res.map(_transformProduct)
    }

    const getAllBanners = async (ids) => {
        const res = await request(`${_api}banners/all`);
        return res.map(_transformBanner)
    }

    const _transformCategory = (category) => {
        return {
            id: category.id,
            name: category.name,
            imgName: category.img_name,
            img: `${_media}public/categories/${category.img_name}`,
        }
    }

    const _transformBanner = (banner) => {
        return {
            id: banner.id,
            name: banner.name,
            imgName: banner.img_name,
            img: `${_media}public/banners/${banner.img_name}`,
        }
    }

    const _transformSubcategory = (subcategory) => {
        return {
            id: subcategory.id,
            name: subcategory.name,
        }
    }

    const _transformProduct = (product) => {
        return {
            id: product.id,
            name: product.name,
            description: product.description,
            price: product.price,
            weight: product.weight,
            imgName: product.img_name,
            img: `${_media}public/products/${product.img_name}`,
        };
    }

    return {
        getAllCategories,
        process,
        setProcess,
        getSubcategoriesOfCategory,
        getProductsOfSubcategory,
        getProductsById,
        getProductsByName,
        getAllBanners

    }
}

export default GrilMenuApi;