import { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';

import Error from '../Error/Error';
import { SkeletonBanner } from '../Skeleton/Skeleton';
import GrilMenuApi from '../../services/GrilMenuApi';

import 'react-multi-carousel/lib/styles.css';
import './Slider.scss';

function SliderHome() {
    const [banners, setBanners] = useState([]);
    const { getAllBanners, process, setProcess } = GrilMenuApi();

    useEffect(() => {
        getAllBanners()
            .then(data => {
                setBanners(data);
                setProcess('confirmed');
            })
            .catch(() => setProcess('error'));
    }, []);

    const setContent = (process, data) => {
        switch (process) {
            case 'waiting':
            case 'loading':
                return <SkeletonBanner />;
            case 'confirmed':
                return <View elements={data} />;
            case 'error':
                return <Error/>;
            default:
                throw new Error('Unexpected process state');
        }
    };

    return (
        <>
            {setContent(process, banners)}
        </>
    );
}

const View = ({ elements }) => {
    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className="slider"
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            dotListClass="dot"
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: 1
                },
                mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0
                    },
                    items: 1
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 1
                }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots
            slidesToSlide={1}
            swipeable
            autoPlay
        >
            {elements.map((elem) => (
                <div key={elem.id} className='slider-slide'>
                    <img src={elem.img} alt={elem.name} />
                </div>
            ))}
        </Carousel>
    );
}

export default SliderHome;
