import './Info.scss';  // Assuming this is where your styles are imported

import phoneIcon from '../../images/icons/phone.svg';
import instagramIcon from '../../images/icons/instagram.svg';

function Info() {
    return (
        <section className="info">
            <div className="info__item">
                <a href="tel:+380684868080">
                    <div className="info__header">
                        <img src={phoneIcon} alt="phone icon" /> +38 (068) 486 80 80
                    </div>
                </a>
            </div>
            <div className="info__item">
                <a href="tel:+380680917035">
                    <div className="info__header">
                        <img src={phoneIcon} alt="phone icon" /> +38 (068) 091 70 35
                    </div>
                </a>
            </div>

            <div className="info__item">
                <a href="https://www.instagram.com/gril_zone/">
                    <div className="info__header">
                        <img src={instagramIcon} alt="instagram icon" /> @gril_zone
                    </div>
                </a>
            </div>
        </section>
    );
}

export default Info;
