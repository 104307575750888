import { useLocation, useNavigate } from 'react-router-dom';
import { BurgerMenuBtn } from '../BurgerMenu/BurgerMenu';

import logo from '../../images/logo.png';
import './Header.scss';

function Header() {
    const location = useLocation();
    const navigate = useNavigate()

    const setTitle = (location) => {
        const path = location.pathname.slice(1);
        
        if (path === '') {
            return 'Головна'; 
        } else if (path.includes('search')) {
            return 'Пошук'; 
        } else if (path.includes('menu')) {
            return 'Меню'; 
        } else if (path.includes('liked')) {
            return 'Обране'; 
        } else if (path.includes('contacts')) {
            return 'Контакти';
        }
        
        return 'Unknown'; 
    }
    
    return (
        <header className="p-15 header">
            <div>
                {setTitle(location) !== 'Головна' ? <button onClick={() => navigate(-1)} className='header__go-back'></button> : <img src={logo} alt="logo" />}
            </div>
            <div>
                <h1>{setTitle(location)}</h1>
            </div>
            <div>
                <BurgerMenuBtn/>
            </div>
        </header>
    );
}

export default Header;
