import { Helmet } from 'react-helmet';

import Delivery from "../../Delivery/Delivery";
import GoogleMap from "../../GoogleMap/GoogleMap";
import Info from "../../Info/Info";

function Contacts() {
    return ( 
        <>
            <Helmet>
                <title>GRIL-ZONE | КОНТАКТИ</title>
            </Helmet>
            <Info/>
            <GoogleMap/>
            <Delivery/>
        </>
     );
}

export default Contacts;