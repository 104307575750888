import { Helmet } from "react-helmet";

import SliderHome from "../../Slider/Slider";
import Instagram from "../../Instagram/Instagram";
import Categories from "../../Categories/Categories";
import WorkTime from "../../WorkTime/WorkTime";
import Delivery from "../../Delivery/Delivery";
import GoogleMap from "../../GoogleMap/GoogleMap";


function Home() {
    return ( 
        <>
            <Helmet>
                <title>GRIL-ZONE | ГОЛОВНА</title>
            </Helmet>

            <Instagram/>
            <SliderHome/>
            <Categories gridType={'square'} title={true}/>
            <WorkTime/>
            <Delivery/>
            <GoogleMap/>
        </>
     );
}

export default Home;