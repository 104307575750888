import { useDetail } from "../../context/DetailContext";
import { ProductItem } from "./Products";

function ProductDetail() {
    const { detailProduct, setDetailProduct } = useDetail()

    const closeDetail = (e) => {
        if(e.target.className === 'product__detail open') {
            setDetailProduct(null)
        }
    }

    return ( 
        <div onClick={closeDetail} className={`product__detail ${detailProduct === null ? null : 'open'}`}>
            <div className="product__detail-wrapper">
                <div className="product__detail-content">
                    {
                        detailProduct === null ? null : <ProductItem elem={detailProduct}/>
                    }
                </div>
            </div>
        </div>
     );
}

export default ProductDetail;