import { useEffect } from 'react';
import { useSubcategory } from '../../context/SubcategoryContext';

import Error from '../Error/Error';
import { SkeletonSubcategory } from '../Skeleton/Skeleton';



import './Subcategories.scss'

function Subcategories() {
    const { subcategories, process, updateCurrentSubcategory } = useSubcategory();

    const handleSubcategoryClick = (subcategoryName) => {
        updateCurrentSubcategory(subcategoryName);
    };

    const setContent = (process, data) => {
        switch (process) {
            case 'waiting':
                return <SkeletonSubcategory/>;
            case 'loading':
                return <SkeletonSubcategory/>;
            case 'confirmed':
                return <View elements={data} handleClick={handleSubcategoryClick} />;
            case 'error':
                return <Error/>;
            default:
                throw new Error('Unexpected process state');
        }
    };

    return ( 
        <section className="subcategory">
            <div className="subcategory__grid">
                {
                    setContent(process, subcategories)
                }
            </div>  
        </section>
     );
}

const View = ({ elements, handleClick }) => {
    const { currentSubcategory } = useSubcategory();

    useEffect(() => {
        const activeElement = document.querySelector('.subcategory__item.active');
        
        if (activeElement) {
            const scrollLeft = activeElement.offsetLeft - (activeElement.parentElement.offsetWidth / 2) + (activeElement.offsetWidth / 2);
            
            activeElement.parentElement.scrollTo({
                left: scrollLeft,
                behavior: 'smooth'
            });
        }
    }, [currentSubcategory]);

    return elements.map(elem => (
        <div
            key={elem.id}
            className={`subcategory__item ${currentSubcategory === elem.name ? 'active' : ''}`}
            onClick={() => handleClick(elem.name)}
        >
            <h3>{elem.name}</h3>
        </div>
    ));
};


export default Subcategories;
