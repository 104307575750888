import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Error from '../../Error/Error';
import GrilMenuApi from '../../../services/GrilMenuApi';
import { ProductItem } from '../../Products/Products';
import { SkeletonProduct } from '../../Skeleton/Skeleton';
import { LikedContext } from '../../../context/LikedContext';

import heartImg from '../../../images/icons/heart.svg'

import './Liked.scss'

function Liked() {
    const [likedProducts, setLikedProducts] = useState([]);
    const { process, setProcess, getProductsById } = GrilMenuApi();
    const { likedItems } = useContext(LikedContext);

    useEffect(() => {
        const likedItemsFromStorage = localStorage.getItem('likedItems');
        if (likedItemsFromStorage !== '[]' && likedItemsFromStorage !== null) {
            const likedItemsArray = JSON.parse(likedItemsFromStorage).map(Number);
            getProductsById(likedItemsArray)
                .then(data => {
                    setLikedProducts(data);
                    setProcess('confirmed');
                })
                .catch(() => setProcess('error'));
        } else {
            setLikedProducts([])
            setProcess('confirmed')
        }
    }, [likedItems]);

    const setContent = (process, data) => {
        switch (process) {
            case 'waiting':
                return <SkeletonProduct />;
            case 'loading':
                return <SkeletonProduct />;
            case 'confirmed':
                return <View elements={data} />;
            case 'error':
                return <Error />;
            default:
                throw new Error('Unexpected process state');
        }
    };

    return (
        <>
            <Helmet>
                <title>GRIL-ZONE | ОБРАНЕ</title>
            </Helmet>
            <section className="product products-liked">
                <div className="product__grid">
                    {setContent(process, likedProducts)}
                </div>

                {
                    likedProducts.length === 0 && process !== 'loading' ?
                        <div className="liked__empty">
                            <img src={heartImg} alt="heart" />
                            <span>Немає обраних</span>
                        </div> : null
                }
            </section>
        </>
    )
}

const View = ({ elements }) => {
    return elements.map((elem) => (
        <ProductItem key={elem.id} elem={elem} onRemove={true} />
    ));
}

export default Liked;
