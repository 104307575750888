import './Skeleton.scss';

export const SkeletonLineCategory = () => (
    <>
        <div className="skeletron-line-category animate"></div>
        <div className="skeletron-line-category animate"></div>
        <div className="skeletron-line-category animate"></div>
        <div className="skeletron-line-category animate"></div>
    </>
);

export const SkeletonCategory = () => (
    <>
        <div className="skeletron-category animate"></div>
        <div className="skeletron-category animate"></div>
        <div className="skeletron-category animate"></div>
        <div className="skeletron-category animate"></div>
    </>
);

export const SkeletonSubcategory = () => (
    <>
        <div className="skeletron-subcategory animate"></div>
        <div className="skeletron-subcategory animate"></div>
        <div className="skeletron-subcategory animate"></div>
        <div className="skeletron-subcategory animate"></div>
        <div className="skeletron-subcategory animate"></div>

    </>
);

export const SkeletonProduct = () => (
  <>
    <div className="skeletron-product animate"></div>
    <div className="skeletron-product animate"></div>
    <div className="skeletron-product animate"></div>
    <div className="skeletron-product animate"></div>
    <div className="skeletron-product animate"></div>
    <div className="skeletron-product animate"></div>

  </>
);

export const SkeletonBanner = () => (
    <>
      <div className="skeletron-banner">
        <div className="skeletron-banner__img animate"></div>
      </div>
    </>
  );