import './Error.scss'

function Error() {
    return ( 
        <div className="error">
            Упс, сталася помилка. <br />
            Перезавантажте сторінку або спробуйте пізніше.
        </div>

     );
}

export default Error;