import './WorkTime.scss';
import { useState } from 'react';

function WorkTime() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const currentDay = new Date().getDay();

    const barSchedule = ["Пн: 08:30-23:00", "Вт: 08:30-23:00", "Ср: 08:30-23:00", "Чт: 08:30-23:00", "Пт: 08:30-24:00", "Сб: 08:30-24:00", "Нд: 10:00-24:00"];
    const kitchenSchedule = ["Пн: 09:30-22:00", "Вт: 09:30-22:00", "Ср: 09:30-22:00", "Чт: 09:30-22:00", "Пт: 09:30-22:00", "Сб: 09:30-22:00", "Нд: 10:00-22:00"];

    return (
        <section className="p-15 accordion">
            <div className="title">Графік роботи</div>
            <div className="accordion-container">
                <div className={`accordion-item ${activeIndex === 0 ? 'active' : ''}`}>
                    <button className="accordion-header" onClick={() => toggleAccordion(0)}>
                        Бар <span className="icon">+</span>
                    </button>
                    <div className={`accordion-content ${activeIndex === 0 ? 'active' : ''}`}>
                        <ul className="schedule">
                            {barSchedule.map((item, index) => (
                                <li key={index} className={currentDay === (index + 1) % 7 ? 'active' : ''}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={`accordion-item ${activeIndex === 1 ? 'active' : ''}`}>
                    <button className="accordion-header" onClick={() => toggleAccordion(1)}>
                        Кухня <span className="icon">+</span>
                    </button>
                    <div className={`accordion-content ${activeIndex === 1 ? 'active' : ''}`}>
                        <ul className="schedule">
                            {kitchenSchedule.map((item, index) => (
                                <li key={index} className={currentDay === (index + 1) % 7 ? 'active' : ''}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WorkTime;
