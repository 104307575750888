import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import React, { useCallback, useState } from 'react';
import SearchInput from "../../SearchInput/SearchInput";
import GrilMenuApi from "../../../services/GrilMenuApi";
import { ProductItem } from '../../Products/Products';

function Search() {
    const { getProductsByName } = GrilMenuApi();
    const [searchResult, setSearchResult] = useState(null);
    const navigate = useNavigate()

    const handleSearchResult = useCallback((data) => {

        navigate('/')
        if (data === null) {
            setSearchResult(null);
        } else if (data.length !== 0) {
            setSearchResult(data);
        } else {
            setSearchResult([]);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>GRIL-ZONE | ПОШУК</title>
            </Helmet>
            <SearchInput getProductsByName={getProductsByName} onSearchResult={handleSearchResult} />

            {searchResult === null ? (
                <div className='p-15'></div>
            ) : (
                searchResult.length !== 0 ? (
                    <View data={searchResult} />
                ) : (
                    <div className='p-15'>Нічого не знайдено :(</div>
                )
            )}

        </>
    );
}

const View = ({ data }) => {
    return (
        <section className="product">
            <div className="product__grid">
                {data.map((elem) => (
                    <ProductItem key={elem.id} elem={elem} />
                ))}
            </div>
        </section>
    );
}

export default Search;
