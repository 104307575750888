import { useState, useEffect, createContext, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GrilMenuApi from '../services/GrilMenuApi';

const CategoriesContext = createContext();

const CategoriesContextProvider = (props) => {
    const [categories, setCategories] = useState([]);
    const { getAllCategories, process, setProcess } = GrilMenuApi();
    const [currentCategory, setCurrentCategoryState] = useState(localStorage.getItem('currentCategory'));
    const location = useLocation();
    const navigate = useNavigate();

    const setCurrentCategory = (category) => {
        setCurrentCategoryState(category);
        localStorage.setItem('currentCategory', category);
    };

    useEffect(() => {
        if (categories.length === 0) {
            getAllCategories()
                .then(data => {
                    setCategories(data);
                    setProcess('confirmed');
                })
                .catch(error => {
                    console.error('Error fetching categories:', error);
                });
        }
    }, []);

    useEffect(() => {
        if (!currentCategory && categories.length > 0) {
            setCurrentCategory(categories[0].id);
        }
    }, [categories, currentCategory]);

    useEffect(() => {
        if (location.pathname.startsWith('/menu/')) {
            const pathParts = location.pathname.split('/');
            if (pathParts.length === 3) {
                const categoryId = pathParts[2];
                setCurrentCategory(categoryId);
            }
        }
    }, [location, setCurrentCategory]);

    return (
        <CategoriesContext.Provider value={{ categories, process, currentCategory, setCurrentCategory }}>
            {props.children}
        </CategoriesContext.Provider>
    );
};

export default CategoriesContextProvider;

export const useCategory = () => useContext(CategoriesContext);
