import { Helmet } from "react-helmet";

import Categories from "../../Categories/Categories";
import Products from "../../Products/Products";
import Subcategories from "../../Subcategories/Subcategories";

import SubcategoriesContextProvider from '../../../context/SubcategoryContext'
import ProductsContextProvider from "../../../context/ProductsContext";

function Menu() {
    return (
        <>
            <Helmet>
                <title>GRIL-ZONE | МЕНЮ</title>
            </Helmet>
            <SubcategoriesContextProvider>
                <Categories gridType={'line'} title={false} />
                <Subcategories />
                <ProductsContextProvider>
                    <Products />
                </ProductsContextProvider>
            </SubcategoriesContextProvider>
        </>
    );
}

export default Menu;