import './GoogleMap.scss';

function GoogleMap() {
    return (
        <section className="p-15 map">
            <iframe
                title="Google Maps Embed"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2660.5077269934977!2d23.2913489!3d48.1775682!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4738294f9c5c5585%3A0x7ace50e7314880cb!2z0J3QsNGA0YbQuNGBICJHUklMTCBaT05FIg!5e0!3m2!1sru!2sua!4v1710341857959!5m2!1sru!2sua"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            />
        </section>
    );
}

export default GoogleMap;
