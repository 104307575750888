import { useState, useEffect, createContext, useContext } from "react";
import { useCategory } from "./CategoryContext";
import { useParams } from "react-router-dom";
import GrilMenuApi from "../services/GrilMenuApi";

const SubcategoriesContext = createContext();

const SubcategoriesContextProvider = (props) => {
  const { currentCategory } = useCategory();
  const { subcategory } = useParams();
  const [subcategories, setSubcategories] = useState([]);
  const [currentSubcategory, setCurrentSubcategory] = useState("");
  const [currentSubcategoryId, setCurrentSubcategoryId] = useState(""); 

  const { getSubcategoriesOfCategory, process, setProcess } = GrilMenuApi();

  useEffect(() => {
    const savedSubcategory = localStorage.getItem("currentSubcategory");
    if (savedSubcategory) {
      setCurrentSubcategory(savedSubcategory);
    }
  }, []);

  useEffect(() => {
    if (currentCategory !== "") {
      getSubcategoriesOfCategory(currentCategory)
        .then((data) => {
          setSubcategories(data);
          setProcess("confirmed");
        })
        .catch((error) => {
          console.error("Error fetching subcategories:", error);
        });
    }
  }, [currentCategory]);

  useEffect(() => {
    if (subcategories.length !== 0) {
      const matchedSubcategory = subcategories.find(
        (sub) => sub.name === subcategory
      );
      if (matchedSubcategory) {
        setCurrentSubcategory(matchedSubcategory.name);
        setCurrentSubcategoryId(matchedSubcategory.id); 
        localStorage.setItem("currentSubcategory", matchedSubcategory.name);
      } else {
        setCurrentSubcategory(subcategories[0].name);
        setCurrentSubcategoryId(subcategories[0].id); 
        localStorage.setItem("currentSubcategory", subcategories[0].name);
      }
    }
  }, [subcategories, subcategory]);

  const updateCurrentSubcategory = (subcategoryName) => {
    const matchedSubcategory = subcategories.find(
      (sub) => sub.name === subcategoryName
    );
    if (matchedSubcategory) {
      setCurrentSubcategory(matchedSubcategory.name);
      setCurrentSubcategoryId(matchedSubcategory.id);
      localStorage.setItem("currentSubcategory", subcategoryName);
    }
  };

  return (
    <SubcategoriesContext.Provider
      value={{
        subcategories,
        process,
        currentSubcategory,
        currentSubcategoryId,
        updateCurrentSubcategory,
      }}
    >
      {props.children}
    </SubcategoriesContext.Provider>
  );
};

export default SubcategoriesContextProvider;

export const useSubcategory = () => useContext(SubcategoriesContext);
