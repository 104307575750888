import { useState, useEffect, createContext, useContext } from "react";
import { useSubcategory } from "./SubcategoryContext";
import GrilMenuApi from '../services/GrilMenuApi';

const ProductsContext = createContext();

const ProductsContextProvider = (props) => {
    const { currentSubcategoryId } = useSubcategory();
    const [products, setProducts] = useState([]);

    const { getProductsOfSubcategory, process, setProcess } = GrilMenuApi();

    useEffect(() => {
        if (currentSubcategoryId !== "") {
            getProductsOfSubcategory(currentSubcategoryId)
            .then(data => {
                setProducts(data);
                setProcess('confirmed');
            });
        }
    }, [currentSubcategoryId]);


    return (
        <ProductsContext.Provider value={{ products, process }}>
            {props.children}
        </ProductsContext.Provider>
    )
}

export default ProductsContextProvider;

export const useProducts = () => useContext(ProductsContext);
