import React, { useState, useEffect } from 'react';
import './SearchInput.scss';

function SearchInput({ getProductsByName, onSearchResult }) {
    const [inFocus, setInFocus] = useState(false);
    const [value, setValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');

    const onFocusInput = (boll) => {
        if (value === '') {
            setInFocus(boll);
        }
    };

    const onFocusInputClose = (boll) => {
        setInFocus(boll);
        setValue('');
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    useEffect(() => {
        if (debouncedValue.trim().length >= 2) { 
            getProductsByName(debouncedValue)
                .then(result => {
                    onSearchResult(result); 
                });
        } else {
            onSearchResult(null); 
        }
    }, [debouncedValue, onSearchResult]);
    

    return ( 
        <section className="p-15 search">
            <div className={`search__wrapper ${inFocus ? 'active' : ''}`}>
                <div className="search__input">
                    <input 
                        type="text"
                        onBlur={() => onFocusInput(false)}
                        onFocus={() => onFocusInput(true)} 
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </div>
                <span onClick={() => onFocusInputClose(false)} className="clear-input">+</span>
            </div>
        </section>
    );
}

export default SearchInput;
