import { createContext, useState } from "react";

export const BurgerMenuContext = createContext()

const BurgerMenuContextProvider = (props) => {
    const [toggle, setToggle] = useState(false)

    return (
        <BurgerMenuContext.Provider value={{ toggle, setToggle }}>
            {props.children}
        </BurgerMenuContext.Provider>
    )
}

export default BurgerMenuContextProvider