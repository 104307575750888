import { useState, useEffect, useContext } from 'react';
import { useProducts } from '../../context/ProductsContext';
import { useSubcategory } from '../../context/SubcategoryContext';
import { LikedContext } from '../../context/LikedContext';
import { SkeletonProduct } from '../Skeleton/Skeleton';
import { useDetail } from '../../context/DetailContext';

import baseImg from '../../images/base.jpeg'
import Error from '../Error/Error';

import './Products.scss';

function Products() {
    const { products, process } = useProducts();
    const { process: subcategoryProcess } = useSubcategory();

    const setContent = (process, data, subcategoryProcess) => {
        if (subcategoryProcess !== 'confirmed') {
            return;
        }
        switch (process) {
            case 'waiting':
            case 'loading':
                return <SkeletonProduct />;
            case 'confirmed':
                return <View elements={data} />;
            case 'error':
                return <Error />;
            default:
                throw new Error('Unexpected process state');
        }
    };

    return (
        <section className="product">
            <div className="product__grid">{setContent(process, products, subcategoryProcess)}</div>
        </section>
    );
}

const View = ({ elements }) => {
    return elements.map((elem) => <ProductItem key={elem.id} elem={elem} />);
};

export const ProductItem = ({ elem, onRemove }) => {
    const [liked, setLiked] = useState(false);
    const [isComponentVisible, setIsComponentVisible] = useState(true);
    const { likedItems, updateLikedItems } = useContext(LikedContext);
    const { setDetailProduct } = useDetail();

    useEffect(() => {
        const likedItems = JSON.parse(localStorage.getItem('likedItems')) || [];
        setLiked(likedItems.includes(elem.id));
    }, [elem.id, likedItems]);

    const onLikeProduct = (e) => {
        setLiked((prevLiked) => !prevLiked);

        const likedItems = JSON.parse(localStorage.getItem('likedItems')) || [];
        if (likedItems.includes(elem.id)) {
            const updatedLikedItems = likedItems.filter((itemId) => itemId !== elem.id);
            localStorage.setItem('likedItems', JSON.stringify(updatedLikedItems));
        } else {
            localStorage.setItem('likedItems', JSON.stringify([...likedItems, elem.id]));
        }
        if (onRemove) {
            setIsComponentVisible(false);
        }

        let likedItemsText = localStorage.getItem('likedItems');
        let likedItemsArray = JSON.parse(likedItemsText);
        updateLikedItems(likedItemsArray);
    };


    const openDetail = (e, elem) => {
        if (!e.target.className.includes('like-btn')) {
            setDetailProduct(elem);
        }
    };

    return isComponentVisible ? (
        <div onClick={(e) => openDetail(e, elem)} key={elem.id} className={`product__item visible ${liked ? 'add' : ''}`}>
            <div className="product__img">
                <img
                    src={elem.imgName === null ? baseImg : elem.img}
                    alt={elem.name}
                />
            </div>
            <div className="product__text">
                <span className="product__name">{elem.name}</span>
                <div className="product__descr">
                    <span className="product__weight">{elem.weight} </span>
                    {elem.description}
                </div>
                <span className="product__price">{elem.price}</span>
            </div>
            <div onClick={onLikeProduct} className="like-btn product__like-btn"></div>
        </div>
    ) : null;
};

export default Products;
