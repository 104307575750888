import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import CategoriesContextProvider from '../../context/CategoryContext';
import { LikedProvider } from '../../context/LikedContext';
import DetailContextProvider from '../../context/DetailContext';
import BurgerMenuContextProvider from '../../context/BurgerMenuContext';
import Header from '../Header/Header';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Contacts from '../pages/Contacts/Contacts';
import Home from '../pages/Home/Home';
import Liked from '../pages/Liked/Liked';
import Menu from '../pages/Menu/Menu';
import Search from '../pages/Search/Search';
import ProductDetail from '../Products/ProductDetail';
import BurgerMenu from '../BurgerMenu/BurgerMenu';

function App() {
    return (
        <div className="container">
            <Router>
                <LikedProvider>
                    <ScrollToTop />
                    <BurgerMenuContextProvider>
                        <Header />
                        <BurgerMenu />
                    </BurgerMenuContextProvider>
                    <CategoriesContextProvider>
                        <DetailContextProvider>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/menu" element={<Menu />} />
                                <Route path="/menu/:categoryName" element={<Menu />} />
                                <Route path="/search" element={<Search />} />
                                <Route path="/liked" element={<Liked />} />
                                <Route path="/contacts" element={<Contacts />} />
                            </Routes>
                            <ProductDetail />
                        </DetailContextProvider>
                    </CategoriesContextProvider>
                    <Navbar />
                    <Footer />
                </LikedProvider>
            </Router>
        </div>
    );
}

export default App;
