import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { BurgerMenuContext } from '../../context/BurgerMenuContext';

import homeIcon from '../../images/icons/home.svg';
import searchIcon from '../../images/icons/search.svg';
import menuIcon from '../../images/icons/menu.svg';
import heartIcon from '../../images/icons/heart.svg';
import phoneIcon from '../../images/icons/phone.svg';
import instagramIcon from '../../images/icons/instagram.svg';

import './BurgerMenu.scss';

function BurgerMenu() {
    const { toggle, setToggle } = useContext(BurgerMenuContext)

    const closeBurgerMenu = (e) => {
        if (e.target.className.includes('burger__wrapper') || e.target.className.includes('burger__nav__link')) {
            setToggle(false)
        }
    }
    return (
        <div onClick={closeBurgerMenu} className={`burger__wrapper ${toggle && 'active'}`}>
            <nav className="burger__content">
                <ul>
                    <li><Link to="/" className="burger__nav__link"><img src={homeIcon} alt="home" />Головна</Link></li>
                    {/* <li><Link to="/search" className="burger__nav__link"><img src={searchIcon} alt="search"/>Пошук</Link></li> */}
                    <li><Link to="/menu" className="burger__nav__link"><img src={menuIcon} alt="menu" />Меню</Link></li>
                    <li><Link to="/liked" className="burger__nav__link"><img src={heartIcon} alt="liked" />Обране</Link></li>
                    <li><Link to="/contacts" className="burger__nav__link"><img src={phoneIcon} alt="contacts" />Зв'язок</Link></li>
                </ul>
                <div className="burger__contacts">
                    <a href="tel:+380684868080">+38 (068) 486 80 80</a>
                    <a href="tel:+380680917035">+38 (068) 091 70 35</a>
                    <a href="https://www.instagram.com/gril_zone/"><img src={instagramIcon} alt="instagram" />gril_zone</a>
                    <p>*Дзвінки з 10:00 до 22:00</p>
                </div>
            </nav>
        </div>
    );
}

export function BurgerMenuBtn() {
    const { toggle, setToggle } = useContext(BurgerMenuContext)
    return (
        <button onClick={() => setToggle(!toggle)} className={`burger-btn ${toggle ? 'active' : null}`}>
            <div className="burger-btn__line burger-btn__line_start"></div>
            <div className="burger-btn__line"></div>
            <div className="burger-btn__line burger-btn__line_end"></div>
        </button>
    );
}

export default BurgerMenu;
