import './Footer.scss'

function Footer() {
    return (  
        <footer className="footer">
            grill-zone.com 2024<br/>copiright ©
        </footer>
    );
}

export default Footer;