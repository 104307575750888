import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { LikedContext } from '../../context/LikedContext';

import homeIcon from '../../images/icons/home.svg';
import searchIcon from '../../images/icons/search.svg';
import menuIcon from '../../images/icons/menu.svg';
import heartIcon from '../../images/icons/heart.svg';
import phoneIcon from '../../images/icons/phone.svg';

import './Navbar.scss';

function Navbar() {
    const { likedItems } = useContext(LikedContext);

    const likedCount = likedItems.length;

    return (
        <nav className="nav">
            <ul>
                <li>
                    <NavLink className="nav__link" to="/">
                        <img src={homeIcon} alt="home" />
                        Головна
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink className="nav__link" to="/search">
                        <img src={searchIcon} alt="search" />
                        Пошук
                    </NavLink>
                </li> */}
                <li>
                    <NavLink className="nav__link" to="/menu">
                        <img src={menuIcon} alt="menu" />
                        Меню
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav__link" to="/liked">
                        <img src={heartIcon} alt="liked" />
                        Обране
                        <div className="nav__liked-counter">
                            {likedCount}
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav__link" to="/contacts">
                        <img src={phoneIcon} alt="contacts" />
                        Зв'язок
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;
