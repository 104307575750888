import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { useCategory } from '../../context/CategoryContext';

import Error from '../Error/Error';
import { SkeletonLineCategory, SkeletonCategory } from '../Skeleton/Skeleton';

import './Categories.scss';

function Categories({ title, gridType }) {
    const { categories, process } = useCategory();
    const containerRef = useRef(null);

    const setContent = (process, data, gridType) => {
        switch (process) {
            case 'waiting':
                return gridType === 'line' ? <SkeletonLineCategory /> : <SkeletonCategory />;
            case 'loading':
                return gridType === 'line' ? <SkeletonLineCategory /> : <SkeletonCategory />
            case 'confirmed':
                return <View gridType={gridType} elements={data} containerRef={containerRef} />;
            case 'error':
                return <Error />;
            default:
                throw new Error('Unexpected process state');
        }
    };

    return (
        <section className={`category ${gridType === 'line' ? 'category_line' : 'p-15'}`}>
            {title ? <div className="title">Категорії</div> : null}
            <div className="category__grid" ref={containerRef}>
                {setContent(process, categories, gridType)}
            </div>
        </section>
    );
}
const View = ({ elements, containerRef, gridType }) => {
    const { setCurrentCategory, currentCategory } = useCategory();


    useEffect(() => {
        const activeElement = containerRef.current.querySelector('.category__item.active');

        if (activeElement) {
            const scrollLeft = activeElement.offsetLeft - (containerRef.current.offsetWidth / 2) + (activeElement.offsetWidth / 2);

            containerRef.current.scrollTo({
                left: scrollLeft,
                behavior: 'smooth'
            });
        }
    }, [currentCategory]);


    const setCategory = (name) => {
        setCurrentCategory(name);
    };

    return elements.map((elem) => (
        <Link key={elem.id} to={`/menu/${elem.id}`}>
            <div
                onClick={() => setCategory(elem.id)}
                key={elem.id}
                className={`category__item ${elem.id === currentCategory ? 'active' : ''}`}
                data-name={elem.name} >
                <img className="category__img" src={elem.img} alt={elem.name} />
                <h2>{elem.name}</h2>
            </div>
        </Link>
    ));
};

export default Categories;
