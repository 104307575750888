import { createContext, useContext, useState } from "react";

const DetailContext = createContext()

const DetailContextProvider = (props) => {
    const [detailProduct, setDetailProduct] = useState(null)

    return (
        <DetailContext.Provider value={{detailProduct, setDetailProduct}}>
            {props.children}
        </DetailContext.Provider>
    )
}
export default DetailContextProvider;

export const useDetail = () => useContext(DetailContext)