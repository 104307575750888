import './Delivery.scss'

function Delivery() {
    return ( 
        <section className="p-15 delivery">
            <div className="title">Доставка</div>
            <div className="delivery__title">Умови доставки</div>
            <p>Доставка здійснюється в місті Хуст. Безкоштовна доставка від 500 грн</p>
            <div className="delivery__title">Як замовити?</div>
            <p>Замовляйте за номером телефону:</p>
            
            <a href="tel:+380684868080">+38 (068) 486 80 80</a>
            <a href="tel:+380680917035">+38 (068) 091 70 35</a>
        </section>
     );
}

export default Delivery;