import { createContext, useState, useEffect } from 'react';

const LikedContext = createContext();

const LikedProvider = ({ children }) => {
    const [likedItems, setLikedItems] = useState([]);

    useEffect(() => {
        const storedLikedItems = JSON.parse(localStorage.getItem('likedItems')) || [];
        setLikedItems(storedLikedItems);
    }, []);

    const updateLikedItems = (newItems) => {
        setLikedItems(newItems);
        localStorage.setItem('likedItems', JSON.stringify(newItems));
    };

    return (
        <LikedContext.Provider value={{ likedItems, updateLikedItems }}>
            {children}
        </LikedContext.Provider>
    );
};

export { LikedContext, LikedProvider };
