import logo from '../../images/logo.png'
import './Instagram.scss'

function Instagram() {
    return (
        <section className="p-15 instagram">
            <a href="https://www.instagram.com/gril_zone/">
                <div className="instagram__img">
                    <img src={logo} alt="logo" />
                </div>
                <div className="instagram__text">
                    <p>gril_zone</p>
                    <span>Стежити</span>
                </div>
                <div className="instagram__btn">
                    Дивитися профіль
                </div>
            </a>
        </section>
    );
}

export default Instagram;
